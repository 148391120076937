import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import GenerateModal from "./GenerateModal";
import copy from "copy-to-clipboard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

function GeneratedListing({ isModalOpen, closeModal, SendInvoice }) {
  const [isgenerate, setIsgenerate] = useState(false);
  const [SendInvoiceData, setSendInvoiceData] = useState();
  const [clipboardClass, setclipboardClass] = useState();
  const [clickIndex, setclickIndex] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const openModalGenerated = () => setIsgenerate(true);
  const closeModalGenerated = () => setIsgenerate(false);
  const [InvoiceListing, setInvoiceListing] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const GenerateInvoice = () => {
    setSendInvoiceData(SendInvoice);
    openModalGenerated(true);
    closeModal(false);
  };

  useEffect(() => {
    if (SendInvoice) {
      setInvoiceListing(SendInvoice.invoices);
    }
  }, [SendInvoice]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const copyToClipboard = (url, index) => {
    copy(url);
    setclipboardClass(url);
    setIsCopied(true);
    setclickIndex(index);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="custom-modal LstingModal"
        overlayClassName="custom-modal-overlay"
      // shouldCloseOnOverlayClick={false} // Disable closing on overlay click
      >
        {isCopied && (
          <div class="alert alert-success">
            <strong>Copied!</strong> {clipboardClass}
          </div>
        )}
        <div className="GenratingText">
          <h2 className="InvoiceText">{SendInvoice?.name} Invoices</h2>
          <button onClick={GenerateInvoice}>New Invoice</button>
        </div>
        <div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="tableHeader">
                <TableRow>
                  <TableCell>Invoice Id</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  {/* <TableCell>Url</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {InvoiceListing?.length === 0 && (
                  <TableRow>
                    <TableCell className="no_data" colSpan={4}>
                      No Data Found !
                    </TableCell>
                  </TableRow>
                )}
                {InvoiceListing.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((row, index) => (
                  <TableRow key={row.invoice_id}>
                    <TableCell>{row.invoice_id}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>
                      {row.status === "created" || row.status === "generate"  || row.status === "sent" ? (
                        <span className="badge bg-primary">sent</span>
                      ) : row.status === "voided" ? (
                        <span className="badge bg-secondary">{row.status}</span>
                      ) : row.status === "paid" ? (
                        <span className="badge bg-success">{row.status}</span>
                      ) : row.status === "failed" ? (
                        <span className="badge bg-danger">{row.status}</span>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    {/* <TableCell
                      onClick={() => copyToClipboard(row.url, index)}
                      className="clipboardNormal"
                    >
                      {" "}
                      {row.url.substring(0, 10) + "*".repeat(10) + row.url.substring(row.url.length - 6)}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={InvoiceListing.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Modal>
      <GenerateModal
        isgenerate={isgenerate}
        closeModalGenerated={closeModalGenerated}
        SendInvoice={SendInvoiceData}
      />
    </div>
  );
}

export default GeneratedListing;
