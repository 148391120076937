import "./App.css";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Configuration from "./component/connection/Configuration";
import PaymentListing from "./component/user-listing/PaymentListing";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import auth from "./service/auth";
import { useNavigate, useParams } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Invoice from "./component/Invoice_listing/Invoice";
function App() {
  const Navigate = useNavigate();
  useEffect(() => {
    let Connectiondata = auth.isConnection();
    if (Connectiondata) {
      Navigate("/payment_listing");
    } else {
      auth.logout();
      Navigate("/:id");
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route element={<UnAuthenticatedRoute Routes />}>
          {/* <Route path="/" element={<Configuration />} /> */}
          <Route path="/:id" element={<Configuration />} />
        </Route>
        <Route element={<AuthenticatedRoute Routes />}>
          <Route path="/payment_listing" element={<PaymentListing />} />
          <Route path="/invoice" element={<Invoice />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
