import React, { useEffect, useState } from "react";
import { ConnectionApi } from "../../service/apiCall";
import { useNavigate, useParams } from "react-router-dom";
import "../Styles.css";
import auth from "../../service/auth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LogoGhl from "../Common/LogoGhl";
const CONNECTION_URL = process.env.REACT_APP_CONNECTION;

export default function Configuration() {
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [LocationId, setLocationId] = useState("");
  const [SiteId, setSiteId] = useState("");
  const [Connecting, setConnecting] = useState(false);
  const [ServerError, setServerError] = useState("");
  const [height, setHeight] = useState('auto');
  const [inputErrors, setInputErrors] = useState({
    LocationId: false,
    apiKey: false,
    SiteId: false,
    accessToken: false,
  });

  useEffect(() => {
    getLocationID();
  }, []);

  useEffect(() => {
    resize();
  }, [apiKey, accessToken]);

  const resize = () => {
    const textarea = document.getElementById('autosize');
    const textarea2 = document.getElementById('accessToken');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
    if (textarea2) {
      textarea2.style.height = 'auto';
      textarea2.style.height = `${textarea2.scrollHeight}px`;
    }
  };

  function getLocationID() {
    try {
      // Location ID
      var l_id = localStorage.getItem("location_id");
      var w_href = window.location.href ? window.location.href.split(":") : "";
      if (l_id) {
        if (l_id == w_href[2]) {
          setLocationId(l_id);
          localStorage.setItem("location_id", l_id);
        } else {
          if (w_href[2] == "id") {
            setLocationId(l_id);
            localStorage.setItem("location_id", l_id);
          } else {
            setLocationId(w_href[2]);
            localStorage.setItem("location_id", w_href[2]);
          }
        }
      } else {
        localStorage.setItem("location_id", w_href[2]);
        setLocationId(w_href[2]);
      }
    } catch (error) { }
  }

  const handleInputChange = (e, inputName) => {
    const value = e.target.value;
    setServerError("");
    const newInputErrors = { ...inputErrors, [inputName]: !value };
    setInputErrors(newInputErrors);
    switch (inputName) {
      case "LocationId":
        setLocationId(value);
        break;
      case "apiKey":
        setApiKey(value);
        break;
      case "SiteId":
        setSiteId(value);
        break;
      case "accessToken":
        setAccessToken(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!LocationId || !apiKey || !SiteId || !accessToken) {
      setInputErrors({
        LocationId: !LocationId,
        apiKey: !apiKey,
        SiteId: !SiteId,
        accessToken: !accessToken,
      });
      return;
    }
    try {
      setConnecting(true);
      const responseData = await ConnectionApi({
        url: CONNECTION_URL,
        postData: {
          locationAPIKeyToken: apiKey,
          location_id: LocationId,
          site_id: SiteId,
          access_token: accessToken,
        },
      });
      auth.connectionUser(responseData);
      localStorage.setItem("isConnection", true);
      let data = {
        accessToken,
        SiteId,
      };
      const dataString = JSON.stringify(data);
      localStorage.setItem("accessToken", dataString);
      navigate("/payment_listing");
    } catch (error) {
      setConnecting(false);
      setServerError(error.response.data.error ? error.response.data.error : "Please enter correct details");
    }
  };

  return (
    <div className="container">
      <div className="brandLogo">
       <LogoGhl/>
      </div>
      {/* <h3 className="mb-4 mainHeading">360Five Checkout</h3> */}
      <div className="containerMain ">
        <div className="form-div">
          <h3 className="mb-4 InvoiceText">Configuration</h3>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <div className="labelHeading">
                <label htmlFor="LocationId">
                  Location Id
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-right">GHL Location Id</Tooltip>}
                  >
                    <span className="me-2">
                      <i className="fa fas fa-info-circle toolTip"></i>
                    </span>
                  </OverlayTrigger>
                </label>
                <a target="_blank" className="aTagLink" href="https://app.gohighlevel.com/sub-accounts/">How to get</a>
              </div>

              <input
                type="text"
                id="LocationId"
                className={inputErrors.LocationId ? "errorColor" : ""}
                value={LocationId}
                onChange={(e) => handleInputChange(e, "LocationId")}
                placeholder="Enter your Location Id"
                disabled={LocationId ? true : false}
              />
            </div>
            <div className={`input-group`}>
              <div className="labelHeading">
                <label htmlFor="apiKey">Location API Key Token
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-right">GHL Location API Key</Tooltip>}
                  >
                    <span className="me-2">
                      {" "}
                      <i className="fa fas fa-info-circle toolTip"></i>
                    </span>
                  </OverlayTrigger>

                </label>

                <a target="_blank" className="aTagLink" href="https://app.gohighlevel.com/settings/api_key">How to get</a>
              </div>
              <textarea
                id="autosize"
                className={inputErrors.apiKey ? "autosize textarea-no-scrollbar errorColor" : "autosize textarea-no-scrollbar"}
                rows="1"
                value={apiKey}
                onChange={(e) => handleInputChange(e, "apiKey")}
                style={{ height }}
                placeholder="Enter your API key"
              />
              <span className="APIKeyDirection">{"You can find it on Setting -> Business Profile -> General Information"}</span>
            </div>
            <div className="input-group">
              <div className="labelHeading">
                <label htmlFor="SiteId">Site Id
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-right">360Five Checkout Site Id</Tooltip>}
                  >
                    <span className="me-2">
                      {" "}
                      <i className="fa fas fa-info-circle toolTip"></i>
                    </span>
                  </OverlayTrigger>

                </label>

                <a target="_blank" className="aTagLink" href="https://stage.360fivecheckout.io/docs/2.0/introduction">How to get</a>
              </div>
              <input
                type="text"
                id="SiteId"
                className={inputErrors.SiteId ? "errorColor" : ""}
                value={SiteId}
                onChange={(e) => handleInputChange(e, "SiteId")}
                placeholder="Enter your Site Id"
              />
            </div>
            <div className="input-group">
              <div className="labelHeading">
                <label htmlFor="accessToken">Access Token
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-right">360Five Checkout Access Token</Tooltip>}
                  >
                    <span className="me-2">
                      {" "}
                      <i className="fa fas fa-info-circle toolTip"></i>
                    </span>
                  </OverlayTrigger>

                </label>

                <a target="_blank" className="aTagLink" href="https://stage.360fivecheckout.io/docs/2.0/authentication">How to get</a>
              </div>
              <textarea
                type="text"
                id="accessToken"
                className={inputErrors.accessToken ? "autosize textarea-no-scrollbar errorColor" : "autosize textarea-no-scrollbar"}
                rows="1"
                value={accessToken}
                onChange={(e) => handleInputChange(e, "accessToken")}
                placeholder="Enter your access token"
              />
            </div>

            {<p className="errorMessage">{ServerError}</p>}
            <button type="submit">
              {Connecting ? "Conncting..." : "Make Connection"}{" "}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
