import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ConnectionApi } from "../../service/apiCall";
import auth from "../../service/auth";
import { fetchDataListing } from "./PaymentListing";
import { useNavigate } from "react-router-dom";
const INVOICE_URL = process.env.REACT_APP_INVOICE;

function GenerateModal({ isgenerate, closeModalGenerated, SendInvoice }) {
  const Navigate = useNavigate();

  const [Description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [memo, setMemo] = useState("");
  const [expire, setExpire] = useState();
  const [tax, setTax] = useState(0);
  const [Amount, setAmount] = useState("");
  const [Email, setEmail] = useState("");
  const [product, setProduct] = useState(false);
  const [noOfItems, setNoOfItems] = useState(0);
  const [Connecting, setConnecting] = useState(false);
  const [Arrayy, setArrayy] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [productDescription, setProductDescription] = useState("");
  const [productName, setProductName] = useState("");
  const [unitPrice, setUnitPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [tip, setTip] = useState(false);
  const [file, setFile] = useState();
  const [ServerError, setServerError] = useState("");
  const [inputErrors, setInputErrors] = useState({
    Description: false,
    Amount: false,
  });


  useEffect(() => {
    if (SendInvoice?.email !== undefined) {
      setEmail(SendInvoice.email);
    }
    setName(SendInvoice?.contactName);
    setPhone(SendInvoice?.phone);
  }, [SendInvoice]);

  const fetchData = async () => {
    try {
      await fetchDataListing();
    } catch (error) {
      console.log(error)
    }

  }

  const handleInputChange = (e, inputName) => {
    const value = e.target.value;

    setServerError("")
    const newInputErrors = { ...inputErrors, [inputName]: !value };
    setInputErrors(newInputErrors);

    switch (inputName) {
      case 'Description':
        setDescription(value);
        break;
      case 'Amount':
        setAmount(value);
        break;
      case 'memo':
        setMemo(value);
        break;
      case 'expire':
        setExpire(value);
        break;
      case 'tax':
        setTax(value);
        break;
      case 'file':
        setFile(e.target.files[0]);
        break;
      case 'noOfItems':
        setNoOfItems(value);
        if (value == "") {
          break;
        }
        if ((value - lineItems.length) >= 2) {
          for (let i = 0; i < value; i++) {
            lineItems.push({
              description: '',
              name: '',
              unit_price: '',
              qty: '',
              total: ''
            })
          }
          break;
        }
        if (lineItems.length <= value) {
          lineItems.push({
            description: '',
            name: '',
            unit_price: '',
            qty: '',
            total: ''
          })
        } else {
          lineItems.pop({
            description: '',
            name: '',
            unit_price: '',
            qty: '',
            total: ''
          })
        }
        break;
      default:
        break;
    }
  };

  const handleInputData = (e, inputName, index) => {
    const value = e.target.value;
    switch (inputName) {
      case 'productDescription':
        setProductDescription(value);
        lineItems[index].description = value;
        break;
      case 'productName':
        setProductName(value);
        lineItems[index].name = value;
        break;
      case 'unitPrice':
        setUnitPrice(value);
        lineItems[index].unit_price = value;
        break;
      case 'quantity':
        setQuantity(value);
        lineItems[index].qty = value;
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let DatFind = auth.getAuth();
    const storedAccessToken = localStorage.getItem('accessToken');
    const retrievedData = JSON.parse(storedAccessToken);
    if (!Description || !Amount) {
      setInputErrors({
        Description: !Description,
        Amount: !Amount,
      });
      return;
    }
    try {
      setConnecting(true)
      if (file) {
        var data = new FormData();
        data.append("file", file);

        var UploadResponse = await ConnectionApi({
          url: `${process.env.REACT_APP_UPLOAD_INVOICE}`,
          headers: {
            Authorization: DatFind.access_token,
            'x-access-token': retrievedData.accessToken,
            mimeType: "multipart/form-data",

          },
          postData: data
        });
      }

      const responseData = await ConnectionApi({
        url: INVOICE_URL,
        postData: {
          description: Description,
          customer_360_id: SendInvoice.customer_360_id,
          amount: Amount,
          id: SendInvoice.id,
          site_id: retrievedData.SiteId,
          first_name: SendInvoice.firstName,
          last_name: SendInvoice.lastName,
          mobile_phone: SendInvoice.phone,
          email: SendInvoice.email,
          name: SendInvoice.contactName,
          tax: tax,
          expire_at: (expire == undefined || expire == null || expire == '' ? "" : expire),
          customer_memo: memo,
          tippingEnabled: tip,
          file: (file ? UploadResponse.url : ''),
          line_items: lineItems
        },
        headers: {
          Authorization: DatFind.access_token,
          'x-access-token': retrievedData.accessToken,
        },
      });
      fetchData();
      if (responseData) {
        closeModalGenerated(false)
        setDescription("")
        setAmount("")
        setServerError("")
        setInputErrors({})
        setConnecting(false)
      }
      Navigate("/payment_listing");
    } catch (error) {
      console.error('Error:', error.message);
      setConnecting(false)
      setServerError("Server Error")
    }
  }

  const closeModalIn = () => {
    closeModalGenerated(false)
    setDescription("")
    setAmount("");
    setMemo("");
    setExpire();
    setTax(0);
    setNoOfItems(0);
    setArrayy([]);
    setUnitPrice(0);
    setQuantity(0);
    setTip(false);
    setProduct(false)
    setFile("");
    setLineItems([])
    setServerError("")
    setInputErrors({})
    setConnecting(false)
  }

  async function deleteRow(index) {
    try {
      var d_ = []
      for (let i = 0; i < lineItems.length; i++) {
        if (i != index) {
          d_.push(lineItems[i])
        }
      }
      setLineItems(d_)
      if (noOfItems != 0) {
        var value = noOfItems - 1;
        setNoOfItems(value)
      }
    } catch (error) {

    }
  }

  return (
    <div>
      <Modal
        isOpen={isgenerate}
        onRequestClose={closeModalIn}
        contentLabel="Example Modal"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="InvoiceButtons">
          <h2 className="InvoiceText cen">New Invoice</h2>
          <p onClick={closeModalIn} style={{ cursor: "pointer" }}><i className="fa fa-times-circle"></i></p>
        </div>
        <div className="container">
          <div className="form-div form__">
            <form onSubmit={handleSubmit}>
              <div className="row bottomGap">
                <div className="col-6">
                  <label htmlFor="Name">Name<span style={{ color: "red", marginLeft: "1px" }}>*</span></label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => handleInputChange(e, 'name')}
                    placeholder="Name"
                    disabled={true} />
                </div>
                <div className="col-6">
                  <label htmlFor="email">Email<span style={{ color: "red", marginLeft: "1px" }}>*</span></label>
                  <input
                    type="email"
                    id="email"
                    value={Email}
                    onChange={(e) => handleInputChange(e, 'email')}
                    placeholder="Email"
                    disabled={true} />
                </div>
              </div>

              <div className="row bottomGap">
                <div className="col-6">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => handleInputChange(e, 'phone')}
                    placeholder="Phone"
                    disabled={true} />
                </div>
                <div className="col-6">
                  <label htmlFor="Amount">Amount<span style={{ color: "red", marginLeft: "1px" }}>*</span></label>
                  <input
                    type="number"
                    id="Amount"
                    className={inputErrors.Amount ? 'errorColor' : ''}
                    value={Amount}
                    onChange={(e) => handleInputChange(e, 'Amount')}
                    placeholder="Enter Amount"
                  />
                </div>
              </div>

              <div className="row bottomGap">
                <div className="col-6">
                  <label htmlFor="Description">Description<span style={{ color: "red", marginLeft: "1px" }}>*</span></label>
                  <input
                    type="text"
                    id="Description"
                    className={inputErrors.Description ? 'errorColor' : ''}
                    value={Description}
                    onChange={(e) => handleInputChange(e, 'Description')}
                    placeholder="Enter Description"
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="memo">Memo</label>
                  <input
                    type="text"
                    value={memo}
                    onChange={(e) => handleInputChange(e, 'memo')}
                    placeholder="Enter Memo"
                  />
                </div>
              </div>

              <div className="row bottomGap">
                <div className="col-6">
                  <label htmlFor="Expire">Expire At</label>
                  <input
                    type="date"
                    value={expire}
                    onChange={(e) => handleInputChange(e, 'expire')}
                    placeholder="Enter Expiration Time"
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="tax">Tax</label>
                  <input
                    type="number"
                    id="tax"
                    value={tax}
                    onChange={(e) => handleInputChange(e, 'tax')}
                    placeholder="Enter Tax"
                  />
                </div>
              </div>

              <div className="row bottomGap">
                <div className="col-6">
                  <label htmlFor="Product">Product</label>
                  <select
                    className="form-select form-select-border"
                    name="layout"
                    data-control="select2"
                    data-hide-search="true"
                    data-placeholder="Select a layout"
                    onChange={(e) => setProduct(!product)}
                  >
                    <option value={true} selected={product == true ? true : false}>Yes</option>
                    <option value={false} selected={product == false ? true : false}>No</option>

                  </select>
                </div>
                <div className="col-6" style={(product == true ? { display: 'block' } : { display: 'none' })}>
                  <label htmlFor="NoOfTimes">No. of Items</label>
                  <input
                    type="number"
                    value={noOfItems}
                    id="noOfItems"
                    min={0}
                    onChange={(e) => handleInputChange(e, 'noOfItems')}
                    placeholder="Enter No Of Products"
                  />
                </div>
                <div className="col-6" style={(product == true ? { display: 'none' } : { display: 'block' })}>
                  <label htmlFor="Tip">Tip</label>
                  <select
                    className="form-select form-select-border"
                    name="layout"
                    data-control="select2"
                    data-hide-search="true"
                    data-placeholder="Select a layout"
                    onChange={(e) => setTip(!tip)}
                  >
                    <option value={true} selected={tip == true ? true : false}>Yes</option>
                    <option value={false} selected={tip == false ? true : false}>No</option>

                  </select>
                </div>
              </div>

              {lineItems?.map((ele, ind) => {
                return <>
                  <div key={ind} className="productData">
                    <div className="row bottomGap">
                      <p className="modalClass" onClick={(e) => deleteRow(ind)} style={{ cursor: "pointer" }}><i className="fa fa-times-circle"></i></p>
                      <div className="col-6">
                        <label htmlFor="ProductDescription">Product Description</label>
                        <input
                          type="text"
                          value={ele.description}
                          onChange={(e) => handleInputData(e, 'productDescription', ind)}
                          placeholder="Enter Product Description"
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="ProductName">Product Name</label>
                        <input
                          type="text"
                          value={ele.name}
                          onChange={(e) => handleInputData(e, 'productName', ind)}
                          placeholder="Enter Product Name"
                        />
                      </div>
                      <div className="col-4 topGap">
                        <label htmlFor="UnitPrice">Unit Price</label>
                        <input
                          type="number"
                          // className={inputErrors.Description ? 'errorColor' : ''}
                          value={ele.unit_price}
                          onChange={(e) => handleInputData(e, 'unitPrice', ind)}
                          placeholder="Enter Unit Price"
                        />
                      </div>
                      <div className="col-4 topGap">
                        <label htmlFor="Quantity">Quantity</label>
                        <input
                          type="number"
                          value={ele.qty}
                          onChange={(e) => handleInputData(e, 'quantity', ind)}
                          placeholder="Enter Quantity"
                        />
                      </div>
                      <div className="col-4 topGap">
                        <label htmlFor="Total">Total</label>
                        <input
                          type="text"
                          value={(Number(ele.qty) * Number(ele.unit_price))}
                          disabled
                          placeholder="Enter Total"
                        />
                      </div>
                    </div>
                  </div>
                </>
              })}

              <div className="bottomGap">
                <div className="row" >
                  <div className="col-6" style={(product == true ? { display: 'block' } : { display: 'none' })}>
                    <label htmlFor="Tip">Tip</label>
                    <select
                      className="form-select form-select-border"
                      name="layout"
                      data-control="select2"
                      data-hide-search="true"
                      data-placeholder="Select a layout"
                      onChange={(e) => setTip(!tip)}
                    >
                      <option value={true} selected={tip == true ? true : false}>Yes</option>
                      <option value={false} selected={tip == false ? true : false}>No</option>

                    </select>
                  </div>
                  <div className="col-6">
                    <label htmlFor="Tip">Attachment</label>
                    <input type="file"
                      name="img"
                      accept="image/*"
                      onChange={(e) => handleInputChange(e, 'file')}
                    />
                  </div>
                </div>
              </div>

              <button type="submit">{Connecting ? "Generating..." : "Sent"} </button>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default GenerateModal;
