import { Navigate, Outlet } from "react-router";
import auth from "./service/auth";
const useAuth = () => {
  return auth.isConnection();
};

const AuthenticatedRoute = () => {
  const isAuth = useAuth();
  // console.log(isAuth,"isssssssssssssssAuthisAuth")
  return isAuth ? <Outlet /> : <Navigate to="/" />;
};
export default AuthenticatedRoute;