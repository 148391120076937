import React from "react";
import Modal from "react-modal";
import auth from "../../service/auth";
import { useNavigate } from "react-router-dom"

function CommonModal({ isModalOpen, closeModal }) {
  const Navigate = useNavigate();

  const DisConnect =()=>{
    auth.logout();
    Navigate("/:id")
  }
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="custom-modal custom-modal1" 
        overlayClassName="custom-modal-overlay" 
        shouldCloseOnOverlayClick={false} // Disable closing on overlay click
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",  
            background: "transparent",
            border: "none",
            color: "black",
          }}
        >
          Close{" "}
        </button>
        <div className="contenttext">
          <h2>Are You Sure !</h2>
          <i className="fa fa-exclamation-circle"></i>
          {/* <i class="fa-solid fa-circle-exclamation"></i> */}
          </div>
        <div className="modal-footer">
         <button type="button" onClick={DisConnect} >Disconnect</button>
         <button type="button" onClick={closeModal} > Cancel</button>
      </div>
      </Modal>
    </div>
  );
}

export default CommonModal;
