import React, { useEffect, useState } from "react";
import Loader from "../Common/Loader";
import { useNavigate } from "react-router-dom";
import UserData from "./UserTable";
import { GetApiCall } from "../../service/apiCall";
import CommonModal from "../Common/CommonModal";
import auth from "../../service/auth";
const USERS_URL = process.env.REACT_APP_USERS;

export const fetchDataListing = async () => {
  let DatFind = auth.getAuth();
    const responseData = await GetApiCall({
      url: USERS_URL,
      headers: {
        Authorization: DatFind.access_token,
        locationAPIKeyToken: DatFind.data.apiKey,
      },
    });
      return responseData.data;
};

const PaymentListing = () => {
  const Navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const TableHead = ["Name", "Email", "Phone", "Invoice"];
  const [users, setUsers] = useState([]);
  const [update, setupdate] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
   try {
    let res = await fetchDataListing();
    setUsers(res.contacts);    
   } catch (error) {
    console.log(error,"Error message")
    if(error.response.data.message === 'jwt expired'){
     auth.logout();
     Navigate("/:id")
    }
   }
    };
       if(!update ===false){
        fetchData();
      }
  }, [update]);

  const RefressData = (res) => {
    setupdate(res)
  };

  return (
    <>
      <div className="container">
        <div className="containerPaymentListing">
          <div className="DisconnectDiv">
            <h3 className="paymentListingUsers InvoiceText">Contacts</h3>
            <button onClick={openModal} className="DisconnectButton">
              Disconnect
            </button>
          </div>
          <div className="tableSty">
            <UserData
              users={users}
              TableHead={TableHead}
              RefressData={RefressData}
            />
          </div>
        </div>
      </div>
      {users?.length === 0 && <Loader />}
      <CommonModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};

export default PaymentListing;
