import axios from "axios"; // Import Axios
export const GetApiCall = async (props) => {
  try {
    const response = await axios.get(props.url, {
      headers: props.headers, // Pass the headers from the props
    });
    return response.data; // Return the response data
  } catch (error) {
    throw error;
  }
};


export const ConnectionApi = async (props) => {
  try {
    // console.log(props.postData, "postData");
    const response = await axios.post(props.url, props.postData, {
      headers:props.headers,
    });
    return response.data; // Return the response data
  } catch (error) {
    throw error;
  }
};
