import { Navigate, Outlet } from "react-router";


const useAuth = () => {
  var isConnection = localStorage.getItem('isConnection') ? true : false;
  const user = { loggedIn: isConnection };
  return user && user.loggedIn;
};


const UnuthenticatedRoute =() => {
  const isAuth =   useAuth();
  return !isAuth ? <Outlet /> : <Navigate to="/" />;
};
export default UnuthenticatedRoute;