
function connectionUser(user) {
  setUser(user);
  localStorage.setItem("isConnection", true);
}

function isConnection() {
  return localStorage.getItem("isConnection") ? true : false;
}

function logout() {
  // localStorage.clear();
  localStorage.removeItem("_user");
  localStorage.removeItem("isConnection");
  localStorage.removeItem("accessToken");
}

function setUser(user) {
  localStorage.setItem("_user", JSON.stringify(user));
}

function getAuth() {
  return JSON.parse(localStorage.getItem("_user"));
}


module.exports = {
  connectionUser: connectionUser,
  isConnection: isConnection,
  logout: logout,
  setUser: setUser,
  getAuth: getAuth,
};
