import React, { useEffect, useState } from "react";
import GeneratedListing from "./GeneratedListing";


const UserTable = ({ users, TableHead, RefressData }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [SendInvoice, setSendInvoice] = useState();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const GenerateInvoice = (data) => {
    setSendInvoice(data)
    openModal(true)
  }
  useEffect(() => {
    RefressData(isModalOpen)
  }, [isModalOpen])

  return (
    <>
      <table className="styled-table">
        <thead>
          <tr className="text-center">
            {TableHead?.map((item, key) => (
              <th key={key}>{item}</th>
            ))}
          </tr>
        </thead>
        {users?.map((item, key) => {
          return (
            <tbody key={key}>
              <tr>
                <td>{item.contactName}</td>
                <td>{item.email}</td>
                <td>{item.phone === "" ? "-" : item.phone}</td>
                <td> <button onClick={() => GenerateInvoice(item)}>View</button></td>
              </tr>
            </tbody>
          );
        })}
      </table>
      <GeneratedListing isModalOpen={isModalOpen} closeModal={closeModal} SendInvoice={SendInvoice} />
    </>
  );
};

export default UserTable;
